import React from 'react';
import { v4 as uuid } from 'uuid';
import { Element } from 'react-scroll';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';

import DeviceTagline from './DeviceTagline';
import Footer from './Footer';
import WhyEcordum from './WhyEcordum';
import VideoBanner from './VideoBanner';

import DeviceGridItem from './DeviceGridItem';
import BlurbText from '../Custom/BlurbText';
import GridContainer from '../Custom/GridContainer';

const gridContent = [
  {
    image: '/CB11/CB11PatchAdhesive.png',
    imageAlt: 'CB11 Wearable Patch Monitor',
    logoImg: '/logos/ecordum-blue.svg',
    logoAlt: 'ecordum',
    title: 'CoreCare™ CB11',
    blurbs: [
      'Wearable patch ECG monitor.',
      'Ultra-lightweight and simple to use.',
      'FDA cleared for Rx apps.',
      'Up to 14 days of continuous Remote Patient Monitoring (RPM) without charging.',
    ],
    drawingImg: '/CB11/CB11patchOnChest.svg',
    drawingAlt: 'CB11 patch on chest',
    urlPath: '/cb11-wearable',
  },
  {
    image: '/CB11/CB11handheldFrontAndBack.png',
    imageAlt: 'CB11 Fob Monitor Front and Back',
    title: 'Patch to Fob Monitor Conversion',
    blurbs: [
      'The CoreCare™ CB11 wearable patch is convertible to a fob by swapping out patch electrodes with thumb pads.',
    ],
    drawingImg: '/CB11/CB11held.svg',
    drawingAlt: 'CB11 hand held',
    urlPath: '/cb11-fob',
  },
  {
    image: '/devices/phones3.png',
    imageAlt: 'iphone and pixel showing app screen',
    blurbs: [
      'CoreCare™ wearable ECG monitors are compatible with iOS, Android and other Bluetooth devices.',
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  line: {
    height: '2px',
    borderRadius: '1px',
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 5%,
      ${theme.palette.secondary.main} 95%)`,
    margin: theme.spacing(4),
  },
  textContainer: {
    textAlign: 'center',
    margin: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  text: {
    color: grey[500],
  },
}));

const HomePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Ecordum | Wearable ECG Monitors for iOS and Android</title>
        <link rel="canonical" href="https://www.ecordum.com/" />
        <meta
          name="description"
          content="Wearable ECG monitors for IDTF, Telehealth & RPM. Wireless connection to iOS and Android devices. Continuous remote patient monitoring, and vital health analysis."
        />
        <meta
          name="keywords"
          content="ECG, EKG, Electrocardiogram, telehealth, remote patient monitoring, mobile cardiac telemetry, independent diagnostic testing facilities, IDTF, RPM, cardiology, cardiac, heart, arrhythmia, atrial fibrillation, ventricular fibrillation, vital health, analysis, wearable, patch, handheld, sensor, monitor"
        />
      </Helmet>
      <VideoBanner />
      <Element name="deviceTagline">
        <DeviceTagline />
      </Element>

      {gridContent.map((content, index) => (
        <DeviceGridItem key={uuid()} index={index} {...content} />
      ))}

      <WhyEcordum />
      <div className={classes.line} />

      <GridContainer
        alignItems="flex-start"
        spacing={0}
        className={classes.textContainer}
      >
        <Grid item xs={12} lg={4}>
          <BlurbText variant="h6" className={classes.text}>
            NMPA (former CFDA) certified
          </BlurbText>
        </Grid>
        <Grid item xs={12} lg={4}>
          <BlurbText variant="h6" className={classes.text}>
            CoreCare™ CB11 patch FDA 510(k) cleared
          </BlurbText>
        </Grid>
        <Grid item xs={12} lg={4}>
          <BlurbText variant="h6" className={classes.text}>
            CE Mark approval for RPM platform
          </BlurbText>
        </Grid>
      </GridContainer>

      <Footer />
    </div>
  );
};

export default HomePage;
