import React from 'react';
import { useField } from 'formik';
import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2, 0, 2, 0),
    minWidth: 120,
  },
}));

const CustomSelect = ({ label, list, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <div>
      <FormControl className={classes.formControl} error={!!errorText}>
        <InputLabel>{label}</InputLabel>
        <Select {...field}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {list.map((item) => (
            <MenuItem key={uuid()} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
