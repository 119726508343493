import { createMuiTheme } from '@material-ui/core/styles';

// Custom theme for the app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#33baef',
      main: '#00a9ec',
      dark: '#0076a5',
      contrastText: '#fff',

    },
    secondary: {
      light: '#43c1a2',
      main: '#15b28b',
      dark: '#0e7c61',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    }
  }
})

export default theme;
