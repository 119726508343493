import React, { createContext } from 'react';
import useMeasure from '../hooks/useMeasure';

export const NavbarHeightContext = createContext();

export const NavbarHeightProvider = (props) => {
  const [navbarRef, bounds] = useMeasure();

  return (
    <NavbarHeightContext.Provider
      value={{ navbarRef, navbarHeight: bounds.bottom }}
    >
      {props.children}
    </NavbarHeightContext.Provider>
  );
};
