import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import red from '@material-ui/core/colors/red';

import ContactForm from '../ContactForm';
import HideOnScroll from './HideOnScroll';
import DeviceMenu from './DeviceMenu';
import DeviceMenuMobile from './DeviceMenuMobile';
import ECGIcon from '../CustomIcons/ECGIcon';
import { NavbarHeightContext } from '../contexts/NavbarHeightContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: (navbarHeight) => `${navbarHeight}px`,
  },
  title: {
    display: 'flex',
    flexGrow: 1,
  },
  logo: {
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoSm: {
    display: 'none',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  covidBtn: {
    backgroundColor: red[600],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const Navbar = () => {
  const { navbarRef, navbarHeight } = useContext(NavbarHeightContext);
  const classes = useStyles(navbarHeight);
  const history = useHistory();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [mobileSubMenuAnchorEl, setMobileSubMenuAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileSubMenuOpen = (event) => {
    setMobileSubMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileSubMenuClose = () => {
    setMobileSubMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const [formOpen, setFormOpen] = useState(false);

  const openForm = () => {
    handleMobileMenuClose();
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };

  const goToCovid19 = () => {
    handleMobileMenuClose();
    history.push('/covid-19');
  };

  const goToAbout = () => {
    handleMobileMenuClose();
    history.push('/about');
  };

  return (
    <div className={classes.root}>
      <HideOnScroll>
        <AppBar ref={navbarRef}>
          <Toolbar>
            <div className={classes.title}>
              <Link to="/">
                <img
                  className={classes.logo}
                  src="/logos/logo-ecordum-white.svg"
                  alt="ecordum"
                />
                <img
                  className={classes.logoSm}
                  src="/logos/logo-white.svg"
                  alt="ecordum"
                />
              </Link>
            </div>

            <div className={classes.sectionDesktop}>
              <Button
                component={Link}
                to="/about"
                color="inherit"
                startIcon={<InfoIcon />}
              >
                About
              </Button>
              <DeviceMenu />
              <Button
                onClick={openForm}
                color="inherit"
                startIcon={<EmailIcon />}
              >
                Contact Us
              </Button>
              <Button
                className={classes.covidBtn}
                component={Link}
                to="/covid-19"
                color="inherit"
                variant="contained"
                disableElevation
                startIcon={<WarningIcon />}
              >
                Covid-19
              </Button>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>

              <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
              >
                <MenuItem onClick={goToAbout}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="About" />
                </MenuItem>
                <MenuItem onClick={handleMobileSubMenuOpen}>
                  <ListItemIcon>
                    <ECGIcon />
                  </ListItemIcon>
                  <ListItemText primary="Monitors" />
                </MenuItem>
                <MenuItem onClick={openForm}>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contact Us" />
                </MenuItem>
                <MenuItem onClick={goToCovid19}>
                  <ListItemIcon>
                    <WarningIcon />
                  </ListItemIcon>
                  <ListItemText primary="COVID-19" />
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <ContactForm formOpen={formOpen} closeForm={closeForm} />
      <DeviceMenuMobile
        anchorEl={mobileSubMenuAnchorEl}
        handleMenuClose={handleMobileSubMenuClose}
      />
    </div>
  );
};

export default Navbar;
