import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './HomePage';
import CB11Patch from './CB11Patch';
import CB11Handheld from './CB11Handheld';
import CB12Handheld from './CB12Handheld';
import PageNotFound from './PageNotFound';
import Covid19 from './Covid19';
import usePageViews from './hooks/usePageViews';
import About from './About';

const Routes = () => {
  usePageViews(); //Google Analytics
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/about" component={About} />
      <Route path="/cb11-wearable" component={CB11Patch} />
      <Route path="/cb11-fob" component={CB11Handheld} />
      <Route path="/covid-19" component={Covid19} />
      <Route render={() => <PageNotFound />} />
    </Switch>
  );
};

export default Routes;
