import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Navbar from './Navbar';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import Routes from './Routes';
import { NavbarHeightProvider } from './contexts/NavbarHeightContext';

const App = () => {
  return (
    <div>
      <NavbarHeightProvider>
        <Router>
          <Navbar />
          <ScrollToTop />
          <Routes />
          <Footer />
        </Router>
      </NavbarHeightProvider>
    </div>
  );
};

export default App;
