import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: grey[200],
  },
  line: {
    height: '1px',
    borderRadius: '1px',
    backgroundColor: grey[500],
    margin: theme.spacing(2, 0, 2, 0),
  },
  info: {
    color: grey[500],
  },
}));

const Footer = () => {
  const classes = useStyles();
  const date = new Date();
  return (
    <div className={classes.root}>
      <Typography className={classes.info} variant="body1" align="center">
        Inquiries: info@eCordum.com
      </Typography>
      <div className={classes.line} />
      <Typography className={classes.info} variant="body2" align="center">
        eCordum © {date.getFullYear()}. All rights reserved.
      </Typography>
    </div>
  );
};

export default Footer;
