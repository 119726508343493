import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ECGIcon from '../CustomIcons/ECGIcon';
import CB11PatchIcon from '../CustomIcons/CB11PatchIcon';
import CB11HandheldIcon from '../CustomIcons/CB11HandheldIcon';
import CB12HandheldIcon from '../CustomIcons/CB12HandheldIcon';

const useStyles = makeStyles((theme) => ({
  menu: {
    // marginTop: theme.spacing(1),
  },
  listItemIcon: {
    paddingLeft: '7px',
  },
}));

const DeviceMenu = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        aria-controls="monitors-menu"
        aria-haspopup="true"
        color="inherit"
        startIcon={<ECGIcon />}
        onClick={handleClickMenu}
      >
        Monitors
      </Button>
      <Menu
        className={classes.menu}
        id="monitors-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleCloseMenu} component={Link} to="cb11-wearable">
          <ListItemIcon>
            <CB11PatchIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="CB11 Wearable" />
        </MenuItem>
        <MenuItem onClick={handleCloseMenu} component={Link} to="cb11-fob">
          <ListItemIcon className={classes.listItemIcon}>
            <CB11HandheldIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="CB11 Fob" />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default DeviceMenu;
