import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import BlurbTitle from './BlurbTitle';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'inline-block',
  },
  logo: {
    width: '128px',
    marginRight: theme.spacing(1),
    marginBottom: '-1px',
    [theme.breakpoints.down('xs')]: {
      width: '116px',
    },
  },
  underline: {
    height: (props) => (props.underlineHeight ? props.underlineHeight : '2px'),
    borderRadius: '1px',
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 5%,
      ${theme.palette.secondary.main} 95%)`,
    margin: (props) => theme.spacing(props.vrtMargin || 3, 'auto'),
  },
}));

const BlurbTitleUnderlined = (props) => {
  const classes = useStyles(props);
  const { logo, alt } = props;

  return (
    <div className={classes.titleContainer}>
      <BlurbTitle {...props}>
        {logo && (
          <span>
            <img className={classes.logo} src={logo} alt={alt} />
          </span>
        )}
        {props.children}
      </BlurbTitle>
      <div className={classes.underline} />
    </div>
  );
};

export default BlurbTitleUnderlined;
