import React from 'react';
import { v4 as uuid } from 'uuid';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';

import DeviceGridItem from './HomePage/DeviceGridItem';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 189px)',
  },
}));

const gridContent = [
  {
    image: '/devices/QTandHV_phone_screens.png',
    imageAlt: 'phone screens showing QT prolongation and HRV with Stress level',
    title: 'COVID-19',
    blurbs: [
      'There is evidence that the drugs used for the treatment of Covid-19 patients result in QT interval prolongation leading to increased risk of Ventricular Fibrillation.',
      'Studies have also shown that Covid-19 patients had significantly reduced Heart Rate Variability (HRV).',
      'eCordum system provides accurate QT prolongation and HRV with Stress level and Overall Heart Wellbeing among other essential parameters.',
    ],
  },
];

const Covid19 = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Ecordum | COVID-19</title>
        <link rel="canonical" href="https://www.ecordum.com/covid-19/" />
        <meta
          name="description"
          content="eCordum system provides accurate QT prolongation and HRV with Stress level and Overall Heart Wellbeing among other essential parameters."
        />
      </Helmet>
      {gridContent.map((content, index) => (
        <DeviceGridItem key={uuid()} index={index} {...content} />
      ))}
    </div>
  );
};

export default Covid19;
