import React, { useRef, useEffect, useState } from 'react';
import {
  Player,
  BigPlayButton,
  ControlBar,
  ReplayControl,
  ForwardControl,
  VolumeMenuButton,
} from 'video-react';

import { makeStyles } from '@material-ui/core/styles';

import '../../../node_modules/video-react/dist/video-react.css'; // import css

const useStyle = makeStyles((theme) => ({
  player: {
    fontFamily: 'Roboto',
    backgroundColor: '#ffff',
    '& div.video-react-poster': {
      backgroundColor: '#ffff',
    },
  },
}));

const VideoPlayer = ({poster, video}) => {
  const classes = useStyle();
  const playerRef = useRef(null);
  const [playerState, setPlayerState] = useState({})

  useEffect(() => {
    playerRef.current.subscribeToStateChange(setPlayerState);
  }, [playerRef]);

  useEffect(() => {
    if(playerState.ended) playerRef.current.load();
  }, [playerState.ended]);

  return (
        <Player
          ref={playerRef}
          className={classes.player}
          poster={poster}
          src={video}
          aspectRatio="16:9"
        >
          <BigPlayButton position="center" />
          <ControlBar autoHideTime={1000}>
            <ReplayControl seconds={5} order={1.1} />
            <ForwardControl seconds={5} order={1.2} />
            <VolumeMenuButton disabled />
          </ControlBar>
        </Player>
  );
};

export default VideoPlayer;
