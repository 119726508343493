import React from 'react';
import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import GridContainer from '../Custom/GridContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: grey[100],
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(0),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },
  specTextContainer: {
    maxWidth: '750px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  line: {
    width: '100%',
    height: '1px',
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 5%,
      ${theme.palette.secondary.main} 95%)`,
    marginTop: theme.spacing(3),
  },
}));

const TechSpecs = (props) => {
  const classes = useStyles(props);
  const { title, specInfo } = props;

  const specText = specInfo.map((info, index) => (
    <Grid
      item
      container
      className={classes.specTextContainer}
      spacing={2}
      justify="center"
      alignItems="center"
      key={uuid()}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h6" className={classes.title}>
          {info.title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1">{info.detail}</Typography>
      </Grid>
      {index + 1 < specInfo.length && <div className={classes.line} />}
    </Grid>
  ));

  return (
    <div className={classes.root}>
      <GridContainer direction="column">
        <Grid item container spacing={0} justify="center" alignItems="center">
            <BlurbTitleUnderlined variant="h5">{title}</BlurbTitleUnderlined>
        </Grid>
        {specText}
      </GridContainer>
    </div>
  );
};

export default TechSpecs;
