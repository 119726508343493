import React, { useRef } from 'react';
import {
  useSpring,
  animated,
  config,
  useChain,
  interpolate,
} from 'react-spring';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';

import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import BlurbTitle from '../Custom/BlurbTitle';

const AnimatedTitle = animated(BlurbTitle);
const AnimatedGrid = animated(Grid);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: '100%',
    backgroundColor: grey[50],
    overflow: 'hidden',
    margin: theme.spacing(0, 0, 12, 0),
    minHeight: '75vh',
  },
  titleContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },
  imgContainer: {
    // height: '100%',
  },
  image: {
    width: '100%',
  },
}));

const Header = () => {
  const classes = useStyles();

  const fadeInRef = useRef();
  const flyInRef = useRef();

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: fadeInRef,
  });

  const { o, xFront, xBack, s } = useSpring({
    from: {
      o: 0,
      xFront: 200,
      xBack: 100,
      s: 0.5,
    },
    to: {
      o: 1,
      xFront: 0,
      xBack: 0,
      s: 1,
    },
    config: config.slow,
    ref: flyInRef,
  });

  useChain([flyInRef, fadeInRef], [0, 1]);

  return (
    <Grid
      container
      className={classes.gridContainer}
      spacing={2}
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        container
        className={classes.titleContainer}
        sx={12}
        md={5}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <BlurbTitleUnderlined
            logo={'/logos/ecordum-blue.svg'}
            alt={'ecordum logo'}
          >
            CoreCare™ CB11
          </BlurbTitleUnderlined>
        </Grid>
        <Grid item xs={12}>
          <AnimatedTitle style={fadeIn}>Fob ECG Monitor</AnimatedTitle>
        </Grid>
      </Grid>

      <Grid
        item
        container
        className={classes.imgContainer}
        xs={12}
        sm={10}
        md={7}
        justify="center"
        alignItems="center"
      >
        <AnimatedGrid
          item
          container
          className={classes.imgContainer}
          xs={6}
          justify="center"
          alignItems="center"
          style={{
            opacity: o.interpolate((o) => o),
            transform: interpolate(
              [xFront, s],
              (xFront, s) => `translate3d(${xFront}%, 0, 0) scale(${s})`
            ),
          }}
        >
          <img
            className={classes.image}
            src={'/CB11/CB11AngleFront.png'}
            alt={'CB11 fob front'}
          />
        </AnimatedGrid>
        <AnimatedGrid
          item
          container
          className={classes.imgContainer}
          xs={6}
          justify="center"
          alignItems="center"
          style={{
            opacity: o.interpolate((o) => o),
            transform: interpolate(
              [xBack, s],
              (xBack, s) => `translate3d(${xBack}%, 0, 0) scale(${s})`
            ),
          }}
        >
          <img
            className={classes.image}
            src={'/CB11/CB11AngleBack.png'}
            alt={'CB11 fob back'}
          />
        </AnimatedGrid>
      </Grid>
    </Grid>
  );
};

export default Header;
