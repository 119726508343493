import React from 'react';
import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptopMedical,
  faFileInvoiceDollar,
  faStopwatch,
  faExclamationTriangle,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt,
  faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';

import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import GridContainer from '../Custom/GridContainer';
import theme from '../../theme';

const whyHighlights = [
  {
    icon: (
      <FontAwesomeIcon
        icon={faCalendarAlt}
        size="3x"
        color={theme.palette.primary.main}
      />
    ),
    title: 'Continuous Long Term Monitoring',
    text: 'The eCordum CoreCare™ CB11 wearable patch is capable of continuously transmitting ECG data to any iOS or Android device for up to 14 days (average 7-9 days) on a single battery. This solves a major hurdle of remote cardiac monitoring.  Competing devices on the market typically need a battery change or a device recharge every 1 to 5 days.',
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faStopwatch}
        size="3x"
        color={theme.palette.primary.main}
      />
    ),
    title: 'Short Term Monitoring',
    text: 'The CoreCare™ CB11 handheld fob ECG monitor provides instant ECG results and in-depth analysis, including Heart Rate Variability (HRV), QT prolongation, general wellbeing, and other vital health analytics. A simple 30 to 180 seconds recording is all it takes.',
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faFileInvoiceDollar}
        size="3x"
        color={theme.palette.primary.main}
      />
    ),
    title: 'Reduced Cost to Provider',
    text: 'Our CoreCare™ CB11 patch monitor is easily converted to a fob ECG monitor for use as an over-the-counter, non-prescription ECG solution.  This saves providers from managing inventory and performing device sanitization per unit.  The cost of a new CoreCare™ CB11 is compatible with the overhead for sanitizing a used device and keeping inventory.',
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faCheckCircle}
        size="3x"
        color={theme.palette.primary.main}
      />
    ),
    title: 'Superior Quality',
    text: 'An independent study involving 200 patients at a cardiology clinic determined the eCordum CoreCare™ family of wearable ECG monitors provided superior accuracy and detail of ECGs compared to all other devices in the study.',
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faLaptopMedical}
        size="3x"
        color={theme.palette.primary.main}
      />
    ),
    title: 'Remote Patient Monitoring',
    text: 'Remote patient monitoring (RPM) is essential for Telehealth/Telemedicine. Due to the unprecedented growth of telehealth and new reimbursement policies there is an urgent need for easy-to-use and low-cost devices providing specific health-related data such as ECG. Most telehealth companies have a RPM platform but only a few have ECG monitoring devices essential for the full-scale RPM.  Typical RPM only includes a weight scale, an oximeter, and BP and HR monitors.  With eCordum CoreCare™, Telehealth providers deepen their commitment to cover the entire spectrum of patient wellbeing.',
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        size="3x"
        color={theme.palette.primary.main}
      />
    ),
    title: 'COVID-19 Monitoring Solution',
    text: 'There is a growing body of evidence that drugs used for the treatment of Covid patients result in ECG QT prolongation leading to increased risk of a deadly ventricular fibrillation, and QT interval should be continuously monitored. With ECG carts in a typical hospital being limited in number, eCordum simple and inexpensive monitors can be an effective solution. Research has also showed that Covid patients had significantly reduced Heart Rate Variability (HRV). eCordum devices and software provide essential parameters such as QT prolongation, HRV, general wellbeing and other for monitoring Covid patients.',
  },
];

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    textAlign: 'center',
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  title: {
    textAlign: 'left',
    fontSize: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
}));

const WhyEcordum = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.headerContainer}>
        <BlurbTitleUnderlined>Why eCordum?</BlurbTitleUnderlined>
      </div>
      <GridContainer alignItems="flex-start" justify="flex-start">
        {whyHighlights.map((why) => (
          <Grid key={uuid()} item container xs={12} md={6} lg={4}>
            <Grid item container>
              {why.icon}
            </Grid>

            <Grid item container>
              <Grid item className={classes.titleContainer}>
                <BlurbTitleUnderlined
                  className={classes.title}
                  underlineHeight="1px"
                  vrtMargin={1}
                >
                  {why.title}
                </BlurbTitleUnderlined>
              </Grid>
              <Grid item>
                <Typography variant="body1">{why.text}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
};

export default WhyEcordum;
