import React, { useEffect, useRef, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import useMeasure from '../hooks/useMeasure';
import { NavbarHeightContext } from '../contexts/NavbarHeightContext';

const useStyles = makeStyles((theme) => ({
  bannerSize: {
    width: '100%',
    height: (navbarHeight) => `calc(100vh - ${navbarHeight}px)`,
    minHeight: '870px',
    [theme.breakpoints.down('lg')]: {
      minHeight: '650px',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '470px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '500px',
    },
  },
  videoContainer: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%,
      ${theme.palette.secondary.main} 100%)`,
  },
  colorOverlay: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%,
      ${theme.palette.secondary.main} 100%)`,
    opacity: 0.8,
  },
  video: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  overlayPosition: {
    position: 'absolute',
    top: (navbarHeight) => `${navbarHeight}px`,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 1),
    },
  },
  textSpacing: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  headerContainer: {
    display: 'inline-block',
  },
  header: {
    color: '#ffffff',
    fontWeight: 400,
    fontSize: '5.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  headerUnderline: {
    height: '4px',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      height: '2px',
    },
  },
  subHeader: {
    color: '#ffffff',
    fontWeight: 300,
    fontSize: '3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.15rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.35rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
}));

const VideoBanner = () => {
  const { navbarHeight } = useContext(NavbarHeightContext);
  const classes = useStyles(navbarHeight);

  const [refContainer, boundsContainer] = useMeasure();
  const videoRef = useRef();

  //makes sure that attribute get passed to iOS Safari
  useEffect(() => {
    const player = videoRef.current;
    if (player) {
      player.autoplay = true;
      player.playsInline = true;
      player.loop = true;
      player.muted = true;
    }
  }, [videoRef.current]);

  const showLandscape = boundsContainer.width / boundsContainer.height > 1;

  return (
    <div
      ref={refContainer}
      className={clsx(classes.videoContainer, classes.bannerSize)}
    >
      {showLandscape ? (
        <video
          key="BannerLoopLandscape"
          ref={videoRef}
          className={classes.video}
          autoPlay
          playsInline
          loop
          muted
        >
          <source src="/videos/EcgBannerLoopLandscape.mp4" type="video/mp4" />
        </video>
      ) : (
        <video
          key="BannerLoopPortrait"
          ref={videoRef}
          className={classes.video}
          autoPlay
          playsInline
          loop
          muted
        >
          <source src="/videos/EcgBannerLoopPortrait.mp4" type="video/mp4" />
        </video>
      )}

      <div
        className={clsx(
          classes.bannerSize,
          classes.colorOverlay,
          classes.overlayPosition
        )}
      />

      <div
        className={clsx(
          classes.textContainer,
          classes.bannerSize,
          classes.overlayPosition
        )}
      >
        <div className={clsx(classes.headerContainer, classes.textSpacing)}>
          <Typography className={classes.header} align="center">
            Our Mission
          </Typography>
          <div className={classes.headerUnderline} />
        </div>
        <Typography
          className={clsx(classes.subHeader, classes.textSpacing)}
          align="center"
        >
          eCordum benefits customers, patients and their doctors through the
          design and development of wearable ECG monitors and related software.
        </Typography>
        <Typography
          className={clsx(classes.subHeader, classes.textSpacing)}
          align="center"
        >
          We recognize the rapidly evolving role of telehealth and the urgent
          need for easy-to-use, low-cost devices delivering medical-grade ECG
          that support diverse clinical needs.
        </Typography>
        <Typography
          className={clsx(classes.subHeader, classes.textSpacing)}
          align="center"
        >
          Our goal is to provide the highest quality personalized ECG solutions
          to detect and monitor cardiac arrhythmias any place and any time.
        </Typography>
      </div>
    </div>
  );
};

export default VideoBanner;
