import React from 'react';
import { useField } from 'formik';
import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3, 0, 3, 0),
    minWidth: 120,
  },
}));

const CustomRadioButtonsGroup = ({ values, label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <FormControl
      className={classes.formControl}
      component="fieldset"
      error={!!errorText}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label={field.name} {...field}>
        {values.map((value) => (
          <FormControlLabel
            key={uuid()}
            value={value}
            control={<Radio />}
            label={value}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default CustomRadioButtonsGroup;
