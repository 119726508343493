import React from 'react';
import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import BlurbText from '../Custom/BlurbText';
import GridContainer from '../Custom/GridContainer';

const codes = {
  RPM: [99453, 99454, 99457, 93798],
  IDTF: [99453, 99454, 99091],
};

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    backgroundColor: grey[100],
    margin: theme.spacing(0, 0, 0, 0),
  },
  title: {
    fontSize: '1.5rem',
  },
  content: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  logo: {
    width: '110px',
    '&:hover': {
      opacity: 0.75,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <GridContainer alignItems="flex-start" className={classes.container}>
      <Grid item xs={12} lg={4}>
        <BlurbTitleUnderlined className={classes.title}>
          Partners
        </BlurbTitleUnderlined>
        <div className={classes.content}>
          <Link href="https://cardiolyse.com/" color="inherit">
            <img
              className={classes.logo}
              src={'/logos/cardiolyse/Cardiolyse-Logo-stacked.svg'}
              alt={'Cardiolyse'}
            />
          </Link>
        </div>
      </Grid>
      <Grid item xs={12} lg={4}>
        <BlurbTitleUnderlined className={classes.title}>
          CPT codes for Telehealth RPM
        </BlurbTitleUnderlined>
        <div className={classes.content}>
          {codes.RPM.map((code) => (
            <BlurbText key={uuid()}>{code}</BlurbText>
          ))}
        </div>
      </Grid>
      <Grid item xs={12} lg={4}>
        <BlurbTitleUnderlined className={classes.title}>
          CPT codes for IDTF
        </BlurbTitleUnderlined>
        <div className={classes.content}>
          {codes.IDTF.map((code) => (
            <BlurbText key={uuid()}>{code}</BlurbText>
          ))}
        </div>
      </Grid>
    </GridContainer>
  );
};

export default Footer;
