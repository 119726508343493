import React from 'react';
import { v4 as uuid } from 'uuid';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import DemoVideo from '../DemoVideo';
import Features from '../CB11Patch/Features';
import TechSpecs from '../CB11Patch/TechSpecs';

const featuresInfo = [
  {
    flexDirection: 'row-reverse',
    text: ['Medical grade short-term cardiac monitoring.'],
    imgSrc: '/CB12/CB12Back.png',
    imgAlt: 'CB12 handheld',
  },
  {
    flexDirection: 'row',
    text: ['Lightweight and simple to use.'],
    imgSrc: '/CB12/CB12held.svg',
    imgAlt: 'CB12 handheld held in hands',
  },
  {
    flexDirection: 'row-reverse',
    text: ['Low power Bluetooth connection to iOS and Android devices.'],
    imgSrc: '/devices/iPhone_CB12_ECG.png',
    imgAlt: 'iPhone showing ECG',
  },
];

const specInfo = [
  {
    title: 'Dimensions',
    detail: '91mm x 46mm x 5mm',
  },
  {
    title: 'HR Range',
    detail: '30~240bpm',
  },
  {
    title: 'Input Dynamic Range',
    detail: '±5mV',
  },
  {
    title: 'Frequency Response',
    detail: '0.67-40Hz (-3dB)',
  },
  {
    title: 'Sampling Rate',
    detail: '250Hz',
  },
  {
    title: 'Battery Type',
    detail: 'CR2032, DC 3V',
  },
  {
    title: 'Connectivity',
    detail: 'Low power Bluetooth',
  },
];

const demoVideoProps = {
  title: 'See How It Works',
  text:
    'Take a 30-180 second recording and see instant ECG results and analysis.',
  poster: 'videos/cb12_iphone_poster.png',
  video: 'videos/CB12HandheldDemo.mp4',
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const CB12Handheld = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Ecordum | CB12 Handheld ECG Monitor</title>
        <link rel="canonical" href="https://www.ecordum.com/cb12-handheld/" />
        <meta
          name="description"
          content="Handheld ECG monitor. Medical grade short-term cardiac monitoring. Takes a 30-180 second ECG recording. Instant ECG results and analysis."
        />
        <meta
          name="keywords"
          content="ECG, EKG, Electrocardiogram, telehealth, remote patient monitoring, independent diagnostic testing facilities, IDTF, cardiology, cardiac, heart, arrhythmia, atrial fibrillation, ventricular fibrillation, vital health, analysis, handheld, sensor, monitor"
        />
      </Helmet>
      <Header />
      <DemoVideo {...demoVideoProps} />

      {featuresInfo.map((info) => (
        <Features key={uuid()} {...info} />
      ))}
      <TechSpecs title="Technical Specs" specInfo={specInfo} />
    </div>
  );
};

export default CB12Handheld;
