import React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SendIcon from '@material-ui/icons/Send';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import CustomTextField from './CustomTextField';
import CustomMultilineTextField from './CustomMultilineTextField';
import CustomSelect from './CustomSelect';
import countryList from './countryList';
import CustomRadioButtonsGroup from './CustomRadioGroup';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/, 'must be letter characters')
    .min(2, 'must be at least 2 characters')
    .max(30, 'must be at most 30 characters')
    .required('please enter a first name'),
  lastName: yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/, 'must be letter characters')
    .min(2, 'must be at least 2 characters')
    .max(30, 'must be at most 30 characters')
    .required('please enter a last name'),
  email: yup
    .string()
    .email('must be a valid email')
    .required('please enter an email address'),
  phone: yup
    .string()
    .matches(/^(?=.=*[0-9])[-. +()0-9]+$/, 'must be a valid phone number')
    .min(7, 'must be at least 7 characters')
    .required('please enter a phone number'),
  country: yup.string().required('please select your country'),
  industry: yup.string().required('please select your industry'),
  message: yup
    .string()
    .min(20, 'must be at least 20 characters')
    .required('please enter a message'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  country: '',
  industry: '',
};

const ContactForm = ({ formOpen, closeForm }) => {
  const classes = useStyles();

  return (
    <Dialog open={formOpen} aria-labelledby="contact-us">
      <DialogTitle id="contact-us">Contact Us</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, actions) => {
            actions.setSubmitting(true);
            axios
              .post('/api/sendContactForm', data)
              .then((response) => {
                if (response.data.status === 'success') {
                  // console.log('Massage Sent');
                  actions.resetForm();
                  closeForm();
                }
                if (response.data.status === 'fail') {
                  console.log('Massage Failed to send.', response.data.err);
                  actions.setStatus('fail');
                }
                actions.setSubmitting(false);
              })
              .catch((error) => console.error(error));
          }}
        >
          {({ values, isSubmitting, status }) => (
            <Form className={classes.form}>
              <CustomTextField name="firstName" label="First Name" />
              <CustomTextField name="lastName" label="Last Name" />
              <CustomTextField name="email" label="Email" />
              <CustomTextField name="phone" label="Phone" />
              <CustomSelect name="country" label="Country" list={countryList} />
              <CustomRadioButtonsGroup
                name="industry"
                label="Industry"
                values={['Telehealth', 'IDTF', 'Hospital Group', 'Other']}
              />
              <CustomMultilineTextField
                name="message"
                label="Message"
                rows="6"
              />
              {status === 'fail' && (
                <Alert severity="error">
                  Something went wrong — your message failed to send!
                </Alert>
              )}
              <DialogActions>
                <Button onClick={closeForm} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </DialogActions>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ContactForm;
