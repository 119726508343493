import React from 'react';
import { useField } from 'formik';

import TextField from '@material-ui/core/TextField';

const CustomMultilineTextField = ({ label, rows, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...field}
      label={label}
      rows={rows}
      multiline
      variant="outlined"
      placeholder="Required"
      margin="normal"
      fullWidth
      helperText={errorText}
      error={!!errorText}
    />
  );
};

export default CustomMultilineTextField;
