import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { Waypoint } from 'react-waypoint';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import BlurbText from '../Custom/BlurbText';
import GridContainer from '../Custom/GridContainer';

const AnimatedBlurbText = animated(BlurbText);
const AnimatedGrid = animated(Grid);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: (props) => props.flexDirection,
    overflow: 'hidden',
  },
  imageContainer: {
    width: (props) => {
      if (
        props.imgSrc === '/CB11/CB11patchOnChest.svg' ||
        props.imgSrc === '/CB11/CB11held.svg'
      )
        return '80%';
      else return '100%';
    },
  },
  image: {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '600px',
    [theme.breakpoints.down('md')]: {
      maxHeight: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '500px',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '400px',
    },
  },
  textContainer: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0),
    },
  },
  lineOfTextWrapper: {
    width: '100%',
    // margin: theme.spacing(1, 0),
    // [theme.breakpoints.down('xs')]: {
    //   margin: theme.spacing(0.5, 0),
    // },
  },
}));

const Features = (props) => {
  const classes = useStyles(props);

  const { flexDirection, text, imgSrc, imgAlt } = props;

  const [showText, setShowText] = useState(false);
  const [showImg, setShowImg] = useState(false);

  const animateText = useSpring({
    opacity: showText ? 1 : 0,
    config: config.slow,
  });

  const x = flexDirection === 'row' ? '100%' : '-100%';
  const animateImg = useSpring({
    opacity: showImg ? 1 : 0,
    transform: showImg ? 'translate3d(0%, 0, 0)' : `translate3d(${x}, 0, 0)`,
    config: config.molasses,
  });

  const handleOnEnterText = (obj) => {
    if (!showText) setShowText(true);
  };

  const handleOnEnterImg = (obj) => {
    if (!showImg) setShowImg(true);
  };

  return (
    <GridContainer className={classes.gridContainer}>
      <Grid
        container
        item
        className={classes.textContainer}
        xs={12}
        md={6}
        justify="center"
        alignItems="center"
      >
        {text.map((lineOfText, index) => (
          <Waypoint bottomOffset="25%" key={index} onEnter={handleOnEnterText}>
            <div className={classes.lineOfTextWrapper}>
              <AnimatedBlurbText style={animateText}>
                {lineOfText}
              </AnimatedBlurbText>
            </div>
          </Waypoint>
        ))}
      </Grid>

      <Waypoint bottomOffset="25%" onEnter={handleOnEnterImg}>
        <AnimatedGrid
          container
          item
          xs={12}
          sm={10}
          md={6}
          justify="center"
          alignItems="center"
          style={animateImg}
        >
          <div className={classes.imageContainer}>
            <img className={classes.image} src={imgSrc} alt={imgAlt} />
          </div>
        </AnimatedGrid>
      </Waypoint>
    </GridContainer>
  );
};

Features.defaultProps = {
  flexDirection: 'row',
  text: [''],
  imgSrc: '',
  imgAlt: '',
};

export default Features;
