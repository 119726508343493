import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem',
    },
    color: (props) =>
      props.logo ? `${theme.palette.secondary.main}` : 'inherit',
  },
}));

const BlurbTitle = (props) => {
  const classes = useStyles(props);
  return (
    <Typography
      align="center"
      {...props}
      className={clsx(classes.title, props.className)}
    />
  );
};

export default BlurbTitle;
