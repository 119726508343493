import React from 'react';
import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import GridContainer from '../Custom/GridContainer';

const teamMembers = [
  // {
  //   name: 'Eric Cheng, PhD',
  //   title: 'Chief Executive Officer',
  //   bio:
  //     '25 years of experience in medical device and consumer electronics development and product realization, author of 11 patents for bio-signal acquisition. Held various technology and management positions at ABAQUS (acquired by Dassault Systèmes), Lucent Technologies, Radius Product Development (acquired by Jabil Inc.) and Nokia Mobile Phone.',
  // },
  {
    name: 'Michael A Torres',
    title: 'Chief Executive Officer',
    bio: 'Senior commercialization leader with a proven ability to deliver exceptional levels of revenue based on sound and strategic planning and superior commercial development. A leader who leverages business analytics as a strategic means to optimize sales opportunities and profitability.',
  },
  {
    name: 'Vladislav Bukhman',
    title: 'President',
    bio: 'Entrepreneur and inventor. Author of 11 patents with deep know-how in the medical area. Some patents and IP assigned to world leading companies.',
  },
  {
    name: 'George Stevens',
    title: 'Chief Technology Officer',
    bio: 'Extensive experience in development unique software solutions, the founder of software company Guidelight Business Solutions, author of 3 computer vision patents.',
  },
  {
    name: 'Kent Stephenson MD, MPH, MBA',
    title: 'Medical Advisor',
    bio: 'Director of Cardiac Electrophysiology and Director of the Clinical Cardiology Fellowship at the Arnot Ogden Heart and Vascular Institute.',
  },
];

const locations = [
  { name: 'Long Island, NY', description: 'Headquarters' },
  { name: 'Ukraine', description: 'Cloud Development' },
  { name: 'China', description: 'Manufacturing, Mobile App Development' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2, 0, 2),
  },
  teamContainer: {
    maxWidth: '1920px',
    marginBottom: theme.spacing(7),
  },
  nameContainer: {
    marginBottom: theme.spacing(0.5),
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
    marginBottom: theme.spacing(2),
  },
  bio: {
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  globalTeamContainer: {
    maxWidth: '1920px',
    marginBottom: theme.spacing(0),
  },
  map: {
    width: '100%',
    maxWidth: '1920px',
  },
}));

const Team = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <BlurbTitleUnderlined>Our Team</BlurbTitleUnderlined>
      </div>

      <GridContainer className={classes.teamContainer} alignItems="flex-start">
        {teamMembers.map((member) => (
          <Grid key={uuid()} item container xs={12} md={6}>
            <Grid item container xs={12}>
              <Grid item className={classes.nameContainer}>
                <BlurbTitleUnderlined
                  className={classes.name}
                  underlineHeight="1px"
                  vrtMargin={1}
                >
                  {member.name}
                </BlurbTitleUnderlined>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.title} color="primary">
                  {member.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.bio}>{member.bio}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </GridContainer>

      <div item className={classes.headerContainer}>
        <BlurbTitleUnderlined>
          A global team of software developers for front-end, cloud and app
          solutions
        </BlurbTitleUnderlined>
      </div>

      <GridContainer
        className={classes.globalTeamContainer}
        alignItems="center"
      >
        {locations.map((location) => (
          <Grid key={uuid()} item container xs={12} md={4}>
            <Grid item container xs={12} justify="center">
              <Grid item className={classes.nameContainer}>
                <BlurbTitleUnderlined
                  className={classes.name}
                  underlineHeight="1px"
                  vrtMargin={1}
                >
                  {location.name}
                </BlurbTitleUnderlined>
              </Grid>

              <Grid item container xs={12} justify="center">
                <Typography className={classes.bio} align="center">
                  {location.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </GridContainer>

      <img
        className={classes.map}
        src="/graphics/globalTeam.svg"
        alt="global location map"
      />
    </div>
  );
};

export default Team;
