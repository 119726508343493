import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(0, 0, 8, 0),
    padding: theme.spacing(1, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0, 0, 6, 0),
    },
  },
}));

const GridContainer = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      justify="center"
      alignItems="center"
      {...props}
      className={clsx(classes.root, props.className)}
    >
      {props.children}
    </Grid>
  );
};

export default GridContainer;
