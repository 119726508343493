import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';

const useStyles = makeStyles((theme) => ({
  deviceTagline: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(6),
    },
  },
}));

const DeviceTagline = () => {
  const classes = useStyles();

  return (
    <div className={classes.deviceTagline}>
      <BlurbTitleUnderlined>
        Wearable and Handheld ECG Monitors For IDTF and RPM
      </BlurbTitleUnderlined>
    </div>
  );
};

export default DeviceTagline;
