import React from 'react';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import BlurbText from '../Custom/BlurbText';
import GridContainer from '../Custom/GridContainer';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      flexDirection: (props) => (props.index % 2 === 1 ? 'row-reverse' : 'row'),
    },
  },
  image: {
    width: '100%',
  },
  description: {
    width: '100%',
    textAlign: 'center',
  },
  drawing: {
    width: (props) => (props.drawingImg === '/CB12/CB12held.svg' ? '100%' : '80%'),
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(6),
  },
}));

const DeviceGridItem = (props) => {
  const classes = useStyles(props);

  const {
    image,
    imageAlt,
    logoImg,
    logoAlt,
    title,
    blurbs,
    drawingImg,
    drawingAlt,
    urlPath,
  } = props;

  const blurbText = blurbs.map((blurb) => (
    <BlurbText key={uuid()}>{blurb}</BlurbText>
  ));

  return (
    <GridContainer className={classes.gridContainer}>
      <Grid
        item
        container
        sx={12}
        md={6}
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <img className={classes.image} src={image} alt={imageAlt} />
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={6}
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <div className={classes.description}>
          {title && (
            <BlurbTitleUnderlined logo={logoImg} alt={logoAlt}>
              {title}
            </BlurbTitleUnderlined>
          )}
          {blurbText}
        </div>
        {drawingImg && (
          <img className={classes.drawing} src={drawingImg} alt={drawingAlt} />
        )}
        {urlPath && (
          <Grid item container xs={12} justify="center" alignItems="center">
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              size="large"
              endIcon={<ArrowForwardIosIcon />}
              component={Link}
              to={urlPath}
            >
              Learn More
            </Button>
          </Grid>
        )}
      </Grid>
    </GridContainer>
  );
};

export default DeviceGridItem;
