import React, { useState } from 'react';
import { useSpring, animated, config, useSprings } from 'react-spring';
import { Waypoint } from 'react-waypoint';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import BlurbText from '../Custom/BlurbText';
import GridContainer from '../Custom/GridContainer';

import useMeasure from '../hooks/useMeasure';

const AnimatedBlurbText = animated(BlurbText);
const AnimatedTypography = animated(Typography);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    overflow: 'hidden',
  },
  imgContainer: {
    width: '100%',
    position: 'relative',
    left: '120%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '45%',
  },
  textContainer: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0),
    },
  },
  animatedText: {
    opacity: 0,
  },
  partText: {
    color: grey[600],
  },
}));

const Destructured = () => {
  const classes = useStyles();

  const [showImg, setShowImg] = useState(false);
  const [animationOn, setAnimationOn] = useState(true);
  const [refBattery, measureBattery] = useMeasure();
  const [refUnit, measureUnit] = useMeasure();

  const electrodeOffset = (measureBattery.height + measureUnit.height) * 0.942;
  const batteryOffset = measureUnit.height * 0.75;

  const items = [
    {
      text: 'Steel Electrodes',
      src: '/CB11Parts/CB11SteelElectrode.png',
      alt: 'steel electrode',
      offset: electrodeOffset,
      zIndex: 10,
      delay: 0,
    },
    {
      text: 'Battery',
      src: '/CB11Parts/BatteryFront.png',
      alt: 'CR2032 battery',
      offset: batteryOffset,
      zIndex: 7,
      delay: 0,
      ref: refBattery,
    },
    {
      text: 'Electronic Unit',
      src: '/CB11Parts/CB11UnitInside.png',
      alt: 'steel electrode',
      offset: 1,
      zIndex: 4,
      delay: 0,
      ref: refUnit,
    },
  ];

  const handleOnEnterImg = () => {
    if (!showImg) setShowImg(true);
  };

  const animateText = useSpring({
    opacity: showImg ? 1 : 0,
    config: config.slow,
    delay: 500,
  });

  const animatePartsText = useSpring({
    opacity: showImg ? 1 : 0,
    config: config.molasses,
    delay: 4500,
  });

  const springs = useSprings(
    items.length,
    items.map((item) => ({
      from: animationOn
        ? //starting position
          { transform: `translate3d(0%, 0px, 0)` }
        : //final position
          { transform: `translate3d(-0%, ${-item.offset}px, 0)` },
      to: async (next, cancel) => {
        if (showImg && animationOn) {
          await next({ transform: `translate3d(-120%, 0px, 0)` });
          await next({ transform: `translate3d(-120%, ${-item.offset}px, 0)` });
          setAnimationOn(false);
        } else if (animationOn) {
          //starting position
          await next({ transform: `translate3d(0%, 0px, 0)` });
        } else {
          //final position
          await next({ transform: `translate3d(-120%, ${-item.offset}px, 0)` });
        }
      },
      config: animationOn ? config.molasses : config.default,
      delay: animationOn ? 300 : 0,
    }))
  );

  const cb11Parts = springs.map((animation, index) => (
    <animated.div
      key={index}
      ref={items[index].ref}
      className={classes.imgContainer}
      style={{
        ...animation,
        zIndex: items[index].zIndex,
        top: items[index].offset,
      }}
    >
      <img
        className={classes.image}
        src={items[index].src}
        alt={items[index].alt}
      />
      <AnimatedTypography
        className={classes.partText}
        variant="body1"
        align="center"
        style={animatePartsText}
      >
        {items[index].text}
      </AnimatedTypography>
    </animated.div>
  ));

  return (
    <GridContainer className={classes.gridContainer}>
      <Grid
        container
        item
        className={classes.textContainer}
        xs={12}
        md={6}
        justify="center"
        alignItems="center"
      >
        <AnimatedBlurbText
          variant="h5"
          className={classes.animatedText}
          style={animateText}
        >
          CB11 patch is convertible to a fob monitor by swapping out patch
          electrodes with steel electrodes thumb pads.
        </AnimatedBlurbText>
      </Grid>

      <Waypoint bottomOffset="43%" onEnter={handleOnEnterImg}>
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={6}
          justify="center"
          alignItems="center"
        >
          {cb11Parts}
        </Grid>
      </Waypoint>
    </GridContainer>
  );
};

export default Destructured;
