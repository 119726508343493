import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

//if in production Google Analytics will be added
const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-167279876-1');
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
};

export default usePageViews;
