import React, { useRef } from 'react';
import { useSpring, animated, config, useChain } from 'react-spring';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';

import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import BlurbTitle from '../Custom/BlurbTitle';

const AnimatedTitle = animated(BlurbTitle);
const AnimatedGrid = animated(Grid);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: '100%',
    backgroundColor: grey[50],
    overflow: 'hidden',
    margin: theme.spacing(0, 0, 12, 0),
    minHeight: '75vh',
  },
  titleContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },
  imgContainer: {
    // overflow: 'hidden',
    // margin: 0,
  },
  image: {
    width: '160%',
  },
}));

const Header = () => {
  const classes = useStyles();

  const fadeInRef = useRef();
  const flyInRef = useRef();

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    // config: config.slow,
    ref: fadeInRef,
  });

  const flyIn = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(100%, 0, 0) scale(0.5)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0%, 0, 0) scale(1)',
    },
    config: config.slow,
    ref: flyInRef,
  });

  useChain([flyInRef, fadeInRef]);

  return (
    <Grid
      container
      className={classes.gridContainer}
      spacing={2}
      justify="flex-end"
      alignItems="center"
    >
      <Grid
        item
        container
        className={classes.titleContainer}
        sx={12}
        md={5}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <BlurbTitleUnderlined
            logo={'/logos/ecordum-blue.svg'}
            alt={'ecordum logo'}
          >
            CoreCare™ CB11
          </BlurbTitleUnderlined>
        </Grid>
        <Grid item xs={12}>
          <AnimatedTitle style={fadeIn}>
            Wearable Patch ECG Monitor
          </AnimatedTitle>
        </Grid>
      </Grid>

      <Grid
        item
        container
        className={classes.imgContainer}
        sx={12}
        sm={10}
        md={7}
        justify="center"
        alignItems="center"
      >
        <AnimatedGrid item style={flyIn}>
          <img
            className={classes.image}
            src={'/CB11/CB11_No_Adhesive.png'}
            alt={'CB11 patch'}
          />
        </AnimatedGrid>
      </Grid>
    </Grid>
  );
};

export default Header;
