import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CB11HandheldIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <g>
          <g>
            <path
              d="M5.7,4.3C5.8,4.4,5.8,4.4,5.7,4.3c0.9,0.9,3.3,3.3,5.9,5.9c3,3,6.3,6.2,7.6,7.5c0.3,0.3,0.5,0.4,0.6,0.5
				c0.1-0.2,0.4-0.9,0.4-1.7V8.9c0-2.8-2.3-5.1-5.1-5.1H7.5C6.8,3.8,6,4.1,5.7,4.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M4.3,5.7C4.1,6,3.8,6.8,3.8,7.5v7.5c0,2.8,2.3,5.1,5.1,5.1l7.6,0c0.8,0,1.4-0.2,1.7-0.4c-0.1-0.1-0.2-0.3-0.5-0.6
				c-1.3-1.3-4.5-4.5-7.5-7.5C7.7,9.1,5.3,6.7,4.3,5.7C4.4,5.8,4.4,5.8,4.3,5.7z"
            />
          </g>
        </g>
      </g>
      <path
        d="M15.8,24H8.2C3.7,24,0,20.3,0,15.8V8.2C0,3.7,3.7,0,8.2,0h7.5C20.3,0,24,3.7,24,8.2v7.5C24,20.3,20.3,24,15.8,24z
        M15.8,22.1c3.5,0,6.3-2.8,6.3-6.3V8.2c0-3.5-2.8-6.3-6.3-6.3H8.2c-3.5,0-6.3,2.8-6.3,6.3v7.5c0,3.5,2.8,6.3,6.3,6.3H15.8z"
      />
    </SvgIcon>
  );
};

export default CB11HandheldIcon;
