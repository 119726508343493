import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import GridContainer from '../Custom/GridContainer';
import BlurbTitleUnderlined from '../Custom/BlurbTitleUnderlined';
import BlurbText from '../Custom/BlurbText';
import VideoPlayer from './VideoPlayer';

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(14),
  },
  description: {
    width: '100%',
    textAlign: 'center',
  },
  playerContainer: {
    maxWidth: '1920px',
    width: '75%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const DemoVideo = ({ title, text, poster, video }) => {
  const classes = useStyle();

  return (
    <GridContainer className={classes.root} spacing={0} direction="column">
      <Grid item container spacing={0} justify="center" alignItems="center">
        <div className={classes.description}>
          <BlurbTitleUnderlined>{title}</BlurbTitleUnderlined>
          <BlurbText>{text}</BlurbText>
        </div>
      </Grid>
      <Grid
        className={classes.playerContainer}
        item
        container
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <VideoPlayer poster={poster} video={video} />
      </Grid>
    </GridContainer>
  );
};

export default DemoVideo;
