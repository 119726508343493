import React from 'react';
import clsx from 'clsx';


import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 500,
    marginBottom: theme.spacing(1.2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
}));

const BlurbText = (props) => {
  const classes = useStyles();
  return (
    <Typography
    variant="h5"
    align="center"
    {...props}
    className={clsx(classes.text, props.className)}
    />
  );
};

export default BlurbText;
