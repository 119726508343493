import React from 'react';
import { v4 as uuid } from 'uuid';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';

import DemoVideo from '../DemoVideo';
import Header from './Header';
import Features from './Features';
import Destructured from './Destructured';
import TechSpecs from './TechSpecs';

const featuresInfo = [
  {
    flexDirection: 'row-reverse',
    text: [
      'Medical grade long-term cardiac monitoring.',
      'FDA cleared for Rx apps.',
    ],
    imgSrc: '/CB11/CB11PatchAdhesive.png',
    imgAlt: 'CB11 wearable patch monitor',
  },
  {
    flexDirection: 'row',
    text: [
      'Up to 14 days of continuous Remote Patient Monitoring (RPM) without charging.',
    ],
    imgSrc: '/CB11/CB11patchOnChest.svg',
    imgAlt: 'CB11 patch on chest',
  },
  {
    flexDirection: 'row-reverse',
    text: ['Low power Bluetooth connection to iOS and Android devices.'],
    imgSrc: '/devices/pixel_CB11_LiveECG.png',
    imgAlt: 'pixel phone showing live ECG',
  },
];

const specInfo = [
  {
    title: 'Dimensions',
    detail: '36.4mm x 36.4mm x 8.2mm',
  },
  {
    title: 'Weight with Battery',
    detail: '8.7g',
  },
  {
    title: 'HR Range',
    detail: '30~240bpm',
  },
  {
    title: 'Input Dynamic Range',
    detail: '±5mV',
  },
  {
    title: 'Frequency Response',
    detail: '0.67-40Hz (-3dB)',
  },
  {
    title: 'Sampling Rate',
    detail: '250Hz',
  },
  {
    title: 'Battery Type',
    detail: 'CR2032, DC 3V',
  },
  {
    title: 'Battery Lifetime',
    detail: 'Up to 14 Days (Average 7-9 Days)',
  },
  {
    title: 'Connectivity',
    detail: 'Low power Bluetooth',
  },
];

const demoVideoProps = {
  title: 'See How It Works',
  text: 'Continuous transmission of ECG data, and comprehensive cardiac and vital health analysis.',
  poster: 'videos/cb11_pixel_poster.png',
  video: 'videos/cb11PatchDemo.mp4',
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const CB11Patch = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Ecordum | CB11 Wearable ECG Monitor</title>
        <link rel="canonical" href="https://www.ecordum.com/cb11-wearable/" />
        <meta
          name="description"
          content="Wearable patch ECG monitor. FDA cleared for Rx apps. Medical grade long-term cardiac monitoring. Up to 14 days of continuous Remote Patient Monitoring (RPM)."
        />
        <meta
          name="keywords"
          content="ECG, EKG, Electrocardiogram, telehealth, remote patient monitoring, independent diagnostic testing facilities, IDTF, RPM, cardiology, cardiac, heart, arrhythmia, atrial fibrillation, ventricular fibrillation, vital health, analysis, wearable, patch, sensor, monitor"
        />
      </Helmet>
      <Header />
      <DemoVideo {...demoVideoProps} />

      {featuresInfo.map((info) => (
        <Features key={uuid()} {...info} />
      ))}
      <Destructured />
      <TechSpecs
        title="Technical Specs - Electronic Unit"
        specInfo={specInfo}
      />
    </div>
  );
};

export default CB11Patch;
