import React from 'react';
import { v4 as uuid } from 'uuid';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Features from '../CB11Patch/Features';
import Destructured from './Destructured';
import TechSpecs from '../CB11Patch/TechSpecs';

const featuresInfo = [
  {
    flexDirection: 'row-reverse',
    text: ['Medical grade short-term cardiac monitoring.'],
    imgSrc: '/CB11/CB11handheldFrontAndBack.png',
    imgAlt: 'CB11 handheld',
  },
  {
    flexDirection: 'row',
    text: [
      'Compact and simple to use.',
      'Takes a 30-180 second ECG recording.',
      'Get instant ECG results and in-depth analysis.',
    ],
    imgSrc: '/CB11/CB11held.svg',
    imgAlt: 'CB11 fob held in hands',
  },
  {
    flexDirection: 'row-reverse',
    text: ['Low power Bluetooth connection to iOS and Android devices.'],
    imgSrc: '/devices/pixel_ECG_port.png',
    imgAlt: 'pixel phone showing ECG',
  },
];

const specInfo = [
  {
    title: 'Dimensions - Electronic Unit',
    detail: '36.4mm x 36.4mm x 8.2mm',
  },
  {
    title: 'Weight with Battery - Electronic Unit',
    detail: '8.7g',
  },
  {
    title: 'Dimensions - Handheld Electrode',
    detail: '36.1mm x 36.1mm x 4.65mm',
  },
  {
    title: 'Weight - Handheld Electrode',
    detail: '4.7g',
  },
  {
    title: 'HR Range',
    detail: '30~240bpm',
  },
  {
    title: 'Input Dynamic Range',
    detail: '±5mV',
  },
  {
    title: 'Frequency Response',
    detail: '0.67-40Hz (-3dB)',
  },
  {
    title: 'Sampling Rate',
    detail: '250Hz',
  },
  {
    title: 'Battery Type',
    detail: 'CR2032, DC 3V',
  },
  {
    title: 'Battery Lifetime',
    detail: 'Up to 14 Days (Average 7-9 Days)',
  },
  {
    title: 'Connectivity',
    detail: 'Low power Bluetooth',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const CB11Handheld = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Ecordum | CB11 Fob ECG Monitor</title>
        <link rel="canonical" href="https://www.ecordum.com/cb11-fob/" />
        <meta
          name="description"
          content="Fob ECG monitor. Medical grade short-term cardiac monitoring. Takes a 30-180 second ECG recording. Instant ECG results and analysis."
        />
        <meta
          name="keywords"
          content="ECG, EKG, Electrocardiogram, telehealth, remote patient monitoring, independent diagnostic testing facilities, IDTF, RPM, cardiology, cardiac, heart, arrhythmia, atrial fibrillation, ventricular fibrillation, vital health, analysis, handheld, fob, sensor, monitor"
        />
      </Helmet>
      <Header />
      {featuresInfo.map((info) => (
        <Features key={uuid()} {...info} />
      ))}
      <Destructured />
      <TechSpecs title="Technical Specs" specInfo={specInfo} />
    </div>
  );
};

export default CB11Handheld;
