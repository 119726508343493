import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ECGIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.8,13.3c-1.9,0-3.3,0.5-4,0.8c-0.5,0.2-1.3,0.3-1.9,0.3h-2.5c-0.5,0-0.9,0.3-1,0.8L14,17.1L12,0.9C12,0.4,11.5,0,11,0
		c0,0,0,0,0,0c-0.5,0-0.9,0.4-1,0.9L8.2,14.6c-0.1,0-0.3-0.1-0.4-0.1h-1l-1.7-2.2C4.9,12.1,4.6,12,4.3,12c0,0,0,0,0,0
		c-0.3,0-0.6,0.1-0.8,0.4l-1.7,2.1H0v2h2.3c0.3,0,0.6-0.1,0.8-0.4l1.2-1.5l1.2,1.5c0.2,0.2,0.5,0.4,0.8,0.4h0.9l0.7,1.3
		c0.2,0.4,0.7,0.6,1.1,0.5c0.4-0.1,0.8-0.4,0.8-0.9L11,8.9l1.8,14.2c0.1,0.5,0.5,0.9,1,0.9c0,0,0,0,0.1,0c0.5,0,0.9-0.3,1-0.8
		l1.5-6.7h1.7c0.4,0,1.6,0,2.7-0.5c0.6-0.2,1.7-0.7,3.2-0.7c0.1,0,0.1,0,0.2,0v-2C23.9,13.4,23.9,13.3,23.8,13.3z"/>
    </SvgIcon>
  );
};

export default ECGIcon;
