import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CB11PatchIcon from '../CustomIcons/CB11PatchIcon';
import CB11HandheldIcon from '../CustomIcons/CB11HandheldIcon';
import CB12HandheldIcon from '../CustomIcons/CB12HandheldIcon';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    paddingLeft: '7px',
  },
}));

const DeviceMenuMobile = (props) => {
  const classes = useStyles();

  const { anchorEl, handleMenuClose } = props;
  const isDeviceMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      id="monitors-menu"
      anchorEl={anchorEl}
      keepMounted
      open={isDeviceMenuOpen}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={handleMenuClose} component={Link} to="cb11-wearable">
        <ListItemIcon>
          <CB11PatchIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary="CB11 Wearable" />
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="cb11-fob">
        <ListItemIcon className={classes.listItemIcon}>
          <CB11HandheldIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="CB11 Fob" />
      </MenuItem>
    </Menu>
  );
};

export default DeviceMenuMobile;
