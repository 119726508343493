import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CB11PatchIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.9,7.8c-1.5,0-2.7,1.2-2.7,2.7v4.1c0,1.5,1.2,2.7,2.7,2.7h4.1c1.5,0,2.7-1.2,2.7-2.7v-4.1c0-1.5-1.2-2.7-2.7-2.7H9.9z
        M9.9,16.4c-1,0-1.7-0.8-1.7-1.7v-4.1c0-1,0.8-1.7,1.7-1.7h4.1c1,0,1.7,0.8,1.7,1.7v4.1c0,1-0.8,1.7-1.7,1.7H9.9z"
      />
      <g>
        <path d="M6.2,10.5c0-0.2,0-0.3,0-0.5H1.8c-1,0-1.8,0.8-1.8,1.8v1.6c0,1,0.8,1.8,1.8,1.8h4.5c0-0.2,0-0.3,0-0.5V10.5z" />
        <path d="M22.2,10h-4.5c0,0.2,0,0.3,0,0.5v4.1c0,0.2,0,0.3,0,0.5h4.5c1,0,1.8-0.8,1.8-1.8v-1.6C24,10.8,23.2,10,22.2,10z" />
      </g>
    </SvgIcon>
  );
};

export default CB11PatchIcon;
