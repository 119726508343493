import React, { useEffect, useRef, useContext } from 'react';
import { Link } from 'react-scroll';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import useMeasure from '../hooks/useMeasure';
import { NavbarHeightContext } from '../contexts/NavbarHeightContext';

const useStyles = makeStyles((theme) => ({
  bannerSize: {
    width: '100%',
    height: (navbarHeight) => `calc(100vh - ${navbarHeight}px)`,
    minHeight: '770px',
    [theme.breakpoints.down('lg')]: {
      minHeight: '650px',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '520px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '450px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '500px',
    },
  },
  videoContainer: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%,
      ${theme.palette.secondary.main} 100%)`,
  },
  colorOverlay: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%,
      ${theme.palette.secondary.main} 100%)`,
    opacity: 0.5,
  },
  video: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  overlayPosition: {
    position: 'absolute',
    top: (navbarHeight) => `${navbarHeight}px`,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 1),
    },
  },
  header: {
    color: '#ffffff',
    fontWeight: 400,
    fontSize: '5.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.25rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  subHeader: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(9),
    color: '#ffffff',
    fontWeight: 300,
    fontSize: '3.75rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      fontSize: '2.35rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(4.5),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(3.5),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(4.5),
    },
  },
  ecgWave: {
    width: '750px',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '670px',
    },
    [theme.breakpoints.down('md')]: {
      width: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '450px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '310px',
    },
  },
}));

const VideoBanner = () => {
  const { navbarHeight } = useContext(NavbarHeightContext);
  const classes = useStyles(navbarHeight);

  const [refContainer, boundsContainer] = useMeasure();
  const videoRef = useRef();

  //makes sure that attribute get passed to iOS Safari
  useEffect(() => {
    const player = videoRef.current;
    if (player) {
      player.autoplay = true;
      player.playsInline = true;
      player.loop = true;
      player.muted = true;
    }
  }, [videoRef.current]);

  const showLandscape = boundsContainer.width / boundsContainer.height > 0.9;

  return (
    <Link to="deviceTagline" smooth={true} duration={500} offset={-25}>
      <div
        ref={refContainer}
        className={clsx(classes.videoContainer, classes.bannerSize)}
      >
        {showLandscape ? (
          <video
            key="BannerLoopLandscape"
            ref={videoRef}
            className={classes.video}
            autoPlay
            playsInline
            loop
            muted
          >
            <source src="/videos/BannerLoop3Landscape.mp4" type="video/mp4" />
          </video>
        ) : (
          <video
            key="BannerLoopPortrait"
            ref={videoRef}
            className={classes.video}
            autoPlay
            playsInline
            loop
            muted
          >
            <source src="/videos/BannerLoop3Portrait.mp4" type="video/mp4" />
          </video>
        )}

        <div
          className={clsx(
            classes.bannerSize,
            classes.colorOverlay,
            classes.overlayPosition
          )}
        />

        <div
          className={clsx(
            classes.textContainer,
            classes.bannerSize,
            classes.overlayPosition
          )}
        >
          <Typography className={classes.header} align="center">
            CoreCare™ Wearable ECG Monitors
          </Typography>
          <Typography className={classes.subHeader} align="center">
            Comprehensive cardiac and vital health analysis for the Telehealth
            Remote Patient Monitoring and Independent Diagnostic Testing
            Facilities (IDTF) markets
          </Typography>
          <img className={classes.ecgWave} src="/ecgWave.png" alt="ecg wave" />
        </div>
      </div>
    </Link>
  );
};

export default VideoBanner;
