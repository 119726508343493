import React from 'react';
import Typography from '@material-ui/core/Typography';

const PageNotFound = () => {
  return (
    <div style={{ height: 'calc(100vh - 209px)' }}>
      <Typography align="center" style={{ marginTop: '84px' }} variant="h4">
        404: Page Not Found
      </Typography>
    </div>
  );
};

export default PageNotFound;
