import React from 'react';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';

import VideoBanner from './VideoBanner';
import Team from './Team';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Ecordum | About</title>
        <link rel="canonical" href="https://www.ecordum.com/about/" />
        <meta
          name="description"
          content="Our goal is to provide the highest quality personalized ECG solutions to detect and monitor cardiac arrhythmias any place and any time."
        />
      </Helmet>
      <VideoBanner />
      <Team />
    </div>
  );
};

export default About;
